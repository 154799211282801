(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Teams
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Teams', Teams);

  function Teams($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/teams/:id', {id: '@_id'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
